<template>
  <LayoutsCommonHeader :groupInfoVisible="groupInfoVisible" />
  <main class="page__main">
    <div v-loading="loading" class="page__main-container">
      <div class="page__main-left" :class="groupInfoVisible ? 'active' : ''">
        <section class="profile">
          <div class="profile__img">
            <img v-if="groupInfo.data_group.group_image_src" :src="groupInfo.data_group.group_image_src" >
            <img v-else src="~/assets/img/room-default.jpg"/>
            <!--<div class="profile__img-overlay" />-->
            <div class="profile__img-cover img-cover">
              <div class="img-cover__owner">
                <img v-if="groupInfo.data_group.create_user_avatar" :src="groupInfo.data_group.create_user_avatar">
                <img v-else src="~/assets/img/profile-default.png">
                <span>{{ groupInfo.data_group.create_pen_name }}</span>
              </div>
              <!--
              <ul class="img-cover__tool">
                <li>
                  <img src="~/assets/img/icon/team.svg">
                  <span>{{ groupInfo.data_group.count_users }}</span>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    fill="none"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M10 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-8l-2-2z"></path>
                  </svg>
                  <span>{{ groupInfo.data_group.count_subject }}</span>
                </li>
                <li>
                  <img src="@/assets/img/icon/icon-document-white.svg" width="18" height="18">
                  <span>{{ groupInfo.data_group.count_submit }}</span>
                </li>
                <li>
                  <svg width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" fill="none" stroke="white" stroke-width="2"/>
                  </svg>
                  <span>{{ groupInfo.data_group.group_like }}</span>
                </li>
              </ul>
              -->
            </div>
          </div>
          <div class="profile__info">
            <div class="profile__team">
              {{ groupInfo.data_group.group_space }}
            </div>
            <div class="profile__title">
              {{ groupInfo.data_group.group_title }}
            </div>
            <div class="profile__description">
              {{ groupInfo.data_group.group_introduce }}
            </div>
            <ul class="profile__num">
              <li>참여자: {{ groupInfo.data_group.count_users }}명</li>
              <li>글감: {{ groupInfo.data_group.count_subject }}개</li>
              <li>글: {{ groupInfo.data_group.count_submit }}개</li>
              <!--<li>좋아요: {{ groupInfo.data_group.group_like }}개</li>-->
            </ul>
          </div>
        </section>
        <section class="write-box">
          <div 
            v-if="(useGroupStore().isOwner || useGroupStore().isMember || authStore.isAdmin) && totalSubjectNum > 0" 
            class="write-box__group mine-active"
            :class="{ active: isOwnActive() }"
          >
            <div class="write-box__group-header" @click="handleClickMyPost()">
              <div class="group-header__title">
                <div class="group-header__avatar">
                  <img v-if="authStore.user_avatar" :src="authStore.user_avatar">
                  <img v-else src="@/assets/img/profile-default.png">
                </div>
                <div class="group-header__name">나의 활동</div>
              </div>
            </div>
          </div>
          <!-- 활동 및 글감 목록 -->
          <div v-for="activity in groupInfo.data_activity" :key="activity.activity_no" class="write-box__group">
            <div class="write-box__group-header" @click="handleSelectActivity(activity)">
              <div class="group-header__title">
                <!--
                <div class="group-header__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    fill="none"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M10 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-8l-2-2z"></path>
                  </svg>
                </div>
                -->
                <div class="group-header__name">{{ activity.activity_title }}</div>
                <span class="group-header__num">
                  ({{ activity.data_subject ? activity.data_subject.length : 0 }})
                  <span v-if="activity.activity_status === 'private'" class="orange_text">(비활성 활동)</span>
                </span>
              </div>
              <button class="group-header__show" :class="{ active: activity.openStatus }">
                <svg width="20" height="10" viewBox="0 0 48 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.64 0.359985L24 18.68L42.36 0.359985L48 5.99998L24 30L0 5.99998L5.64 0.359985Z" fill="black"/>
                </svg>
              </button>
            </div>
            <ul v-if="activity.data_subject" class="group__list" :class="{ active: activity.openStatus }">
              <li
                v-for="(subject, index) in activity.data_subject"
                :key="subject.subject_no"
                class="group__item"
                :class="{ active: isSelectedSubject(activity, subject) }"
                @click="handleSelectSubject(activity, subject)"
              >
                <div>
                  <span class="subject-circle" />
                  <span v-if="index + 1 < activity.data_subject.length" class="subject-line" />
                  <div class="subject-title">{{ subject.subject_title }}</div>
                </div>
                <span>{{ subject.submit_user_count }}/{{ groupInfo.data_users.length }}</span>
              </li>
            </ul>
          </div>
          <div v-if="useGroupStore().isOwner || useGroupStore().isMember || authStore.isAdmin" class="write-box__group">
            <div class="write-box__group-header" @click="handleVisibleUserList()">
              <div class="group-header__title group-header__title--member">
                <div class="group-header__name">그룹 멤버</div>
                <span class="group-header__num">({{ userList.length }})</span>
              </div>
              <div>
                <el-button
                  v-if="useGroupStore().isOwner || authStore.isAdmin"
                  size="small"
                  type="info"
                  style="margin-right: 5px"
                  @click.stop="handleVisibleInvite()"
                >
                  초대
                </el-button>
                <button class="group-header__show" :class="{ active: userListVisible }">
                  <svg width="20" height="10" viewBox="0 0 48 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.64 0.359985L24 18.68L42.36 0.359985L48 5.99998L24 30L0 5.99998L5.64 0.359985Z" fill="black"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="user-list" :class="{ active: userListVisible }">
              <table class="user-list__table">
                <col width="55">
                <col>
                <col width="45">
                <col width="45">
                <col width="45">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>제출</th>
                    <th>미제출</th>
                    <th>작성글</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in ownerList" :key="user.userId">
                    <td class="align--center" style="position: relative;">
                      <img v-if="user.userAvatar" :src="user.userAvatar">
                      <img v-else src="~/assets/img/profile-default.png">
                      <span class="owner-crown">
                        <ClientOnly>
                          <font-awesome-icon :icon="faCrown" />
                        </ClientOnly>
                      </span>
                    </td>
                    <td>
                      <div>{{ user.penName }}</div>
                      <div style="color: #4A5568; font-size: 0.9em">{{ user.userLogin }}</div>
                    </td>
                    <td class="align--center">{{ $filters.parseKrw(user.publishPostCount) }}</td>
                    <td class="align--center">
                      <span style="color: #C53030;">{{ $filters.parseKrw(user.totalPostCount - user.publishPostCount) }}</span>
                      </td>
                    <td class="align--center">{{ $filters.parseKrw(user.totalPostCount) }}</td>
                  </tr>
                  <tr v-if="memberList.length > 0" class="dividing-line">
                    <td colspan="5">참여자</td>
                  </tr>
                  <tr
                    v-for="user in memberList"
                    :key="user.userId"
                    style="cursor: pointer;"
                    @click="$router.push({ name: 'room-roomId-member-post', params: { roomId: $route.params.roomId }, query: { memberNo: user.userId } })"
                  >
                    <td class="align--center" style="position: relative;">
                      <img v-if="user.userAvatar" :src="user.userAvatar">
                      <img v-else src="~/assets/img/profile-default.png">
                    </td>
                    <td>
                      <div>{{ user.penName }}</div>
                      <div style="color: #4A5568; font-size: 0.9em">{{ user.userLogin }}</div>
                    </td>
                    <td class="align--center">{{ $filters.parseKrw(user.publishPostCount) }}</td>
                    <td class="align--center">
                      <span style="color: #C53030;">{{ $filters.parseKrw(user.totalPostCount - user.publishPostCount) }}</span>
                      </td>
                    <td class="align--center">{{ $filters.parseKrw(user.totalPostCount) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
      <div class="main__right" :class="groupInfoVisible ? '' : 'active'">
        <NuxtPage
          :group="groupInfo.data_group"
          :activityList="groupInfo.data_activity"
          :activity="selectedActivity"
          :subject="selectedSubject"
          :users="userList"
          :totalSubjectNum="totalSubjectNum"
        />
      </div>
    </div>
    <el-dialog v-model="inviteVisible" width="570px" title="초대하기" center>
      <RoomInvite v-if="inviteVisible" />
    </el-dialog>
    <el-dialog
      v-model="emailVerificationVisible"
      title="메일 확인"
      center
      width="500px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <EmailVerification @success="handleSuccessVerificationEmail()"/>
    </el-dialog>
    <el-dialog v-model="inviteStatusVisible" width="600px" center title="🎉 축하합니다! 🎉">
      <RoomInviteStatus v-if="inviteStatusVisible" :room="route.params.roomId" :invite-status="inviteStatus" />
    </el-dialog>

  </main>
  <footer class="page__footer">
  </footer>
</template>

<script setup lang="ts">
import { getV2GroupUserList } from "~/composables/java/v2Groups";
import { getUserInfo } from "~/composables/java/auth";
import { getGroupInformation } from "~/composables/java/groups";
import { faCrown } from '@fortawesome/pro-solid-svg-icons'; 
import type { RouteLocationNormalized } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const route = useRouter().currentRoute.value;
const loading = ref(true);
const groupInfo = ref({
  data_group: {},
  data_activity: [],
  data_users: [],
});
const selectedActivity = ref(null);
const selectedSubject = ref({
  subject_no: null,
});
const renderignNum = ref(0);
const inviteVisible = ref(false);
const userListVisible = ref(false);
const userList = ref([]);
const emailVerificationVisible = ref(false);
const totalSubjectNum = ref(-1);
const groupInfoVisible = ref(true);
const subjectStore = useSubjectStore();
const authStore = useAuthStore();
const router = useRouter();

const inviteStatusVisible = ref(false);
const inviteStatus = ref('');


/**
 * 초대된 방문자이면 축하 메시지 노출 및 router에서 status 제거
 */
 function checkInvitedUser() {
  if (route.query && route.query.status) {
    inviteStatusVisible.value = true;
    inviteStatus.value = (Array.isArray(route.query.status) ? route.query.status[0] : route.query.status) as string;
    const { status, ...restQuery } = route.query; // status를 제외한 나머지 쿼리만 추출
    router.replace({ query: restQuery });
  }
}


onMounted(async () => {
  if (authStore.isLogin === false) {
    useRouter().push({ name: 'login' });
    return;  // 로그인 되어있지 않다면 다음 코드를 실행하지 않는다.
  }

  if ( await getInfo() === false) {
    useRouter().replace({ name: 'room' });
    return;
  }
  
  if (authStore.user_classify === 'teacher' && !authStore.user_email_verified) {
    emailVerificationVisible.value = true;
  }

  checkGroupInfoVisible(router.currentRoute); // 초기 라우터 상태 체크

  if (!route.query.activityNo && !route.query.subjectNo && route.name === 'room-roomId') {
    groupInfoVisible.value = true;
  }

  checkInvitedUser();
})


/*
  * 활동 글감 목록에서 선택된 글감인지 확인
  * @param {Object} activity - 활동 정보
  * @param {Object} subject - 글감 정보
  * @returns {boolean} - 선택된 글감이면 true, 아니면 false
  */
  function isSelectedSubject(activity: any, subject: any) {
  return router.currentRoute.value.name === 'room-roomId' && selectedActivity.value?.activity_no === activity.activity_no && selectedSubject.value?.subject_no === subject.subject_no;
}

/*
 * 나의 활동 페이지인지 확인
 */
function isOwnActive() {
  return router.currentRoute.value.name === 'room-roomId-own';
}


const ownerList = computed(() => {
  const list: any = [];
  userList.value.forEach((user: any) => {
    if (user.userId === groupInfo.value.data_group.create_user_no) {
      list.push(user);
    }
  });
  return list;
})

const memberList = computed(() => {
  const list: any = [];
  userList.value.forEach((user: any) => {
    if (user.userId !== groupInfo.value.data_group.create_user_no) {
      list.push(user);
    }
  });
  return list;
})

/**
 * 그룹 정보(활동 및 글감 목록)가 표시되는지 여부를 체크하는 함수
 * @param newRoute
 */
function checkGroupInfoVisible(newRoute: Ref<RouteLocationNormalized>) {
  const routeQuery = newRoute.value.query;
  const subjectNo = routeQuery?.subjectNo;
  const activityNo = routeQuery?.activityNo;

  if (subjectNo) {
    groupInfo.value.data_activity.some((activity: any) => {
      if (activity.activity_no == activityNo) {
        activity.data_subject?.some((subject: any) => {
          if (subject.subject_no == subjectNo) {
            selectedActivity.value = activity;
            selectedSubject.value = subject;
            groupInfoVisible.value = false; // 그룹 정보를 숨김 (글감 화면 표시)
            return true; // 일치하는 항목을 찾으면 루프를 종료
          }
          return false;
        })
      }
      return false;
    });
  } else {
    subjectStore.resetSubject();
    groupInfoVisible.value = true;
    selectedSubject.value = {
      subject_no: null,
    };
  }
  if (router.currentRoute.value.name === 'room-roomId-own') {
    groupInfoVisible.value = false;
    return;
  }
  if (router.currentRoute.value.name === 'room-roomId-analytics') {
    groupInfoVisible.value = false;
    return;
  }
}

watch(
  () => router.currentRoute,
  (newRoute) => {
    checkGroupInfoVisible(newRoute);
  },
  { deep: true } // query 객체 내부 변화를 감지
);


/**
 * 그룹 정보를 가져오는 함수
 */
 async function getInfo() {
  let groupData: any = null;

  if (!route.params.roomId) {
    ElMessage.error({ message: "잘못된 그룹 접근입니다.", type: 'error'});
    router.replace('/room');
    return false; // 실패 시 false 반환
  }

  try {
    loading.value = true;
    const response = await getGroupInformation(Array.isArray(route.params.roomId) ? route.params.roomId[0] : route.params.roomId);
    groupData = response.data;
  } catch (e) {
    const message = e.response?.data?.message || e.message || '그룹정보 요청을 실패했습니다.';
    ElMessage.error({ message, type: 'error'});
    return false; // 실패 시 false 반환
  } finally {
    loading.value = false;
  }

  if (!groupData) {
    ElMessage.error({ message: "잘못된 그룹 정보입니다.", type: 'error'});
    return false; // 실패 시 false 반환
  }  

  groupInfo.value = groupData.data.list;

  // ====== 그룹 상태 체크 ======
  
  if(groupInfo.value.data_group.group_status === 'trash') {
    ElMessage({
      message: '삭제된 그룹입니다.',
      type: 'error',
    });
    return false; // 실패 시 false 반환
  }

  const isMember = memberAccessAuth(groupInfo.value.data_users);
  const isOwner = groupInfo.value.data_group.create_user_no === authStore.user_id;
  if (groupInfo.value.data_group.group_status === 'private' && (!isOwner && !isMember && !authStore.isAdmin)) {
    ElMessage.warning('잘못된 요청입니다.');
    return router.replace('/room');
  }
  // ====== 그룹 상태 체크 끝 ======

  useGroupStore().setGroup(groupInfo.value.data_group, groupInfo.value.data_users); // 그룹 정보 저장

  if (groupData.data.list.data_activity && groupData.data.list.data_activity.length > 0) {

    // ===== 활동별 글감 수 계산 =====
    totalSubjectNum.value = groupInfo.value.data_activity.reduce((total, activity) => {
      return total + (activity.data_subject?.length || 0);
    }, 0);

    // ===== 모든 openStatus 상태 초기화 =====
    groupInfo.value.data_activity.forEach((activity: any) => {
      activity.openStatus = false;
    });

    const activityNo = Number(route.query.activityNo);
    const subjectNo = Number(route.query.subjectNo);

    // ===== route에 activityNo와 subjectNo가 있을 경우 해당 글감 선택 =====
    if (activityNo && subjectNo) {
      groupInfo.value.data_activity.find((activity: any) => {
        const subject = activity.data_subject?.find((subject: any) => subject.subject_no === subjectNo);
        if (activity.activity_no === activityNo && subject) {
          activity.openStatus = true;
          selectedActivity.value = activity;
          selectedSubject.value = subject;
          return true;
        }
        return false;
      });
    } else {
      // ===== 첫번째 활동과 글감 선택 =====
      for (const activity of groupInfo.value.data_activity) {
        const subjects = activity.data_subject || [];
        if (subjects.length > 0) {
          activity.openStatus = true;
          selectedActivity.value = activity;
          selectedSubject.value = subjects[0];
          break; 
        }
      }
    }
  }

  // 그룹 멤버 정보 요청
  // getGroupInformation2에 포함된 user정보로는 부족한 부분 항목이 있어 별도로 요청 
  try {
    loading.value = true;

    const res2 = await getV2GroupUserList(route.params.roomId)

    if (!res2.data) {
      ElMessage.error({ message: "잘못된 그룹 정보입니다.", type: 'error'});
      return false; // 실패 시 false 반환
    }  

    userList.value = res2.data;
  } catch (e) {
    const message = e.response?.data?.message || e.message || '그룹 멤버 정보 요청을 실패했습니다.';
    ElMessage.error({ message, type: 'error'});
    return false; // 실패 시 false 반환
  } finally {
    loading.value = false;
  }

  return true;
}


function handleSelectActivity(activity: any) {
  activity.openStatus = !activity.openStatus;
  // if (selectedActivity.value.activity_no !== activity.activity_no) selectedActivity.value = activity;
}

function handleSelectSubject(activity: any, subject: any) {
  selectedActivity.value = activity;
  selectedSubject.value = subject;
  renderignNum.value = renderignNum.value + 1;
  const router = useRouter();
  if (router.currentRoute.value.name === 'room-roomId-own') {
    router.push({ 
      name: 'room-roomId', params: { roomId: route.params.roomId }, 
      query: { activityNo: selectedActivity.value.activity_no, subjectNo: subject.subject_no }});
  } else {
    router.push({ query: { activityNo: selectedActivity.value.activity_no, subjectNo: subject.subject_no }});
  }
}

function handleVisibleUserList() {
  userListVisible.value = !userListVisible.value;
}

function handleClickMyPost() {
  selectedActivity.value = { activity_no: 0 };
  selectedSubject.value = { subject_no: 0 };
  useRouter().push({ name: 'room-roomId-own' });
}

function handleVisibleInvite() {
  inviteVisible.value = !inviteVisible.value
}

function handleSuccessVerificationEmail() {
  getUserInfo().then((res) => {
    if (res.data.user_info) {
      emailVerificationVisible.value = false;
      useRouter().go(0);
    } else {
      ElMessage.error(res.data.message || res.data.error || '회원 정보를 불러오지 못했습니다.');
    }
  }).catch(() => {
    ElMessage.error('메일 확인에 실패했습니다. 네트워크 상태를 확인해주세요.');
  });
}

</script>

<style lang="scss" scoped>
@import url("~/assets/css/layouts/group.scss");
</style>>
